<template>
  <div v-if="usersData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="4">{{ $t("Users.data") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
          <th>{{ $t("Users.phone") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in usersData" :key="user.userToken">
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table"
              :src="fullPathFileFromServer(user.userImagePath, defaultImg)"
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(user.fullCode) }}</td>
          <td>
            {{ isDataExist(user.userNameCurrent) }}
          </td>
          <td class="table-phone-number">
            {{ isDataExist(user.userPhoneWithCC) }}
          </td>
          <td class="fmenu-item-container">
            <FloatingMenu>
              <li>
                <button
                  @click="setUserData(user)"
                  v-b-modal.UserChangePassword
                  :title="$t('changePassword')"
                >
                  <img src="@/assets/images/changePassword.svg" />
                </button>
              </li>
              <li v-if="hasResetCode">
                <button
                  @click="
                    setUserTokenReset(user.userToken);
                    setIsResetTypeLoginCode(true);
                  "
                  v-b-modal.UserLoginCodeReset
                  :title="$t('UserLoginCodes.resetCode')"
                >
                  <img src="@/assets/images/UserLoginCodes.svg" />
                </button>
              </li>
              <li v-if="hasResetDevice">
                <button
                  @click="
                    setUserTokenReset(user.userToken);
                    setIsResetTypeLoginCode(false);
                  "
                  v-b-modal.UserLoginCodeReset
                  :title="$t('UserLoginCodes.resetDevice')"
                >
                  <img src="@/assets/images/pc.svg" />
                </button>
              </li>
              <li
                v-if="
                  checkPrivilege(hasUserWalletTransaction()) &&
                  modelName == 'students'
                "
              >
                <router-link
                  :to="{
                    name: 'UserWalletTransactions',
                    params: {
                      userToken: user.userToken,
                    },
                  }"
                  :title="$t('UserWalletTransactions.modelName')"
                >
                  <img
                    src="@/assets/images/userWalletTransactions.svg"
                    class="icon-lg"
                  />
                </router-link>
              </li>
              <li>
                <button
                  @click="
                    setUserData(user);
                    openBottomSheet('UserInfo');
                  "
                  :title="$t('info')"
                >
                  <img src="@/assets/images/info.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setUserData(user);
                    openBottomSheet('UserQRCode');
                  "
                  :title="$t('general.qrCode')"
                >
                  <img src="@/assets/images/qr-code.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeEdit">
                <button
                  @click="
                    setUserData(user);
                    openBottomSheet('UserUpdate');
                  "
                  :title="$t('edit')"
                >
                  <img src="@/assets/images/pencil.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeStudentParentrEdit">
                <button
                  @click="
                    updateWithParents(user);
                    openBottomSheet('UserUpdate');
                  "
                  :title="$t('StudentParents.edit')"
                >
                  <img src="@/assets/images/pencil.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeFinaleDelete">
                <button
                  v-b-modal.UserDelete
                  :title="$t('delete')"
                  @click="setUserData(user)"
                >
                  <img src="@/assets/images/trash.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeChangeActivationType">
                <button
                  v-b-modal.UserChangeActivationType
                  :title="$t('changeActivationType')"
                  @click="setUserData(user)"
                >
                  <img src="@/assets/images/changeActivationType.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setUserData(user);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../utils/functions";
import {
  hasAdminsEgyptEdit,
  hasUserWalletTransaction,
  hasAdminsEgyptFinaleDelete,
  hasAdminsEgyptChangeActivationType,
  hasMasterAdminEdit,
  hasMasterAdminFinaleDelete,
  hasMasterAdminChangeActivationType,
  hasEmployeeEdit,
  hasEmployeeFinaleDelete,
  hasEmployeeChangeActivationType,
  hasStudentEdit,
  hasStudentFinaleDelete,
  hasStudentChangeActivationType,
  hasParentEdit,
  hasParentFinaleDelete,
  hasParentChangeActivationType,
  hasAdminsEgyptLoginCodeResetCode,
  hasAdminsEgyptLoginCodeResetDevice,
  hasMasterAdminLoginCodeResetCode,
  hasMasterAdminLoginCodeResetDevice,
  hasEmployeeLoginCodeResetCode,
  hasEmployeeLoginCodeResetDevice,
  hasStudentLoginCodeResetCode,
  hasStudentLoginCodeResetDevice,
  hasParentLoginCodeResetCode,
  hasParentLoginCodeResetDevice,
} from "./../../utils/privilegeHelper";
import FloatingMenu from "./../general/FloatingMenu.vue";
import generalMixin from "./../../utils/generalMixin";

export default {
  name: "UsersTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["usersData", "filterData", "defaultImg", "modelName"],
  data() {
    return {
      storageSpacesRoute: this.$route.meta.StorageSpacesRoute,
      userTypeToken: "",
    };
  },
  methods: {
    async updateAndGetUserFinanceStatistic(userToken) {
      this.$emit("updateAndGetUserFinanceStatistic", userToken);
    },
    setUserData(user) {
      this.$emit("setUserData", user);
    },
    updateWithParents(user) {
      this.$emit("updateWithParents", user);
    },
    setUserTokenReset(token) {
      this.$emit("setUserTokenReset", token);
    },
    setIsResetTypeLoginCode(status) {
      this.$emit("setIsResetTypeLoginCode", status);
    },
    goToUserProfileTabs(user) {
      this.userTypeToken = user.userTypeToken;
      let routeName = "";

      if (this.hasUserFamily()) {
        routeName = "UserFamilies";
      } else if (this.hasUserQualification()) {
        routeName = "UserQualifications";
      } else if (this.hasUserPersonalCard()) {
        routeName = "UserPersonalCards";
      } else if (this.hasUserTrainingCourse()) {
        routeName = "UserTrainingCourses";
      } else if (this.hasUserExperience()) {
        routeName = "UserExperiences";
      } else if (this.hasUserScientificDegree()) {
        routeName = "UserScientificDegrees";
      }

      this.$router
        .push({
          name: routeName,
          params: {
            userTypeToken: user.userTypeToken,
            userToken: user.userToken,
          },
        })
        .catch(() => {});
    },

    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasAdminsEgyptEdit,
    hasUserWalletTransaction,
    hasAdminsEgyptFinaleDelete,
    hasAdminsEgyptChangeActivationType,
    hasMasterAdminEdit,
    hasMasterAdminFinaleDelete,
    hasMasterAdminChangeActivationType,
    hasEmployeeEdit,
    hasEmployeeFinaleDelete,
    hasEmployeeChangeActivationType,
    hasStudentEdit,
    hasStudentFinaleDelete,
    hasStudentChangeActivationType,

    hasParentEdit,
    hasParentFinaleDelete,
    hasParentChangeActivationType,
  },
  computed: {
    hasPrivilegeStudentParentrEdit() {
      let status = false;
      switch (this.modelName) {
        case "students":
          status = checkPrivilege(this.hasStudentEdit());
          break;
        case "parents":
          status = checkPrivilege(this.hasParentEdit());
          break;
      }
      return status;
    },
    hasPrivilegeEdit() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(this.hasAdminsEgyptEdit());
          break;
        case "masterAdmins":
          status = checkPrivilege(this.hasMasterAdminEdit());
          break;
        case "employees":
          status = checkPrivilege(this.hasEmployeeEdit());
          break;
        case "students":
          status = checkPrivilege(this.hasStudentEdit());
          break;

        case "parents":
          status = checkPrivilege(this.hasParentEdit());
          break;
      }
      return status;
    },
    hasPrivilegeFinaleDelete() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(this.hasAdminsEgyptFinaleDelete());
          break;
        case "masterAdmins":
          status = checkPrivilege(this.hasMasterAdminFinaleDelete());
          break;
        case "employees":
          status = checkPrivilege(this.hasEmployeeFinaleDelete());
          break;
        case "students":
          status = checkPrivilege(this.hasStudentFinaleDelete());
          break;
        case "parents":
          status = checkPrivilege(this.hasParentFinaleDelete());
          break;
      }
      return status;
    },
    hasPrivilegeChangeActivationType() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(this.hasAdminsEgyptChangeActivationType());
          break;
        case "masterAdmins":
          status = checkPrivilege(this.hasMasterAdminChangeActivationType());
          break;
        case "employees":
          status = checkPrivilege(this.hasEmployeeChangeActivationType());
          break;
        case "suppliers":
          status = checkPrivilege(this.hasSupplierChangeActivationType());
          break;
        case "parents":
          status = checkPrivilege(this.hasParentChangeActivationType());
          break;
      }
      return status;
    },

    hasResetCode() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(hasAdminsEgyptLoginCodeResetCode());
          break;
        case "masterAdmins":
          status = checkPrivilege(hasMasterAdminLoginCodeResetCode());
          break;
        case "employees":
          status = checkPrivilege(hasEmployeeLoginCodeResetCode());
          break;
        case "students":
          status = checkPrivilege(hasStudentLoginCodeResetCode());
          break;

        case "parents":
          status = checkPrivilege(hasParentLoginCodeResetCode());
          break;
      }
      return status;
    },
    hasResetDevice() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(hasAdminsEgyptLoginCodeResetDevice());
          break;
        case "masterAdmins":
          status = checkPrivilege(hasMasterAdminLoginCodeResetDevice());
          break;
        case "employees":
          status = checkPrivilege(hasEmployeeLoginCodeResetDevice());
          break;
        case "students":
          status = checkPrivilege(hasStudentLoginCodeResetDevice());
          break;

        case "parents":
          status = checkPrivilege(hasParentLoginCodeResetDevice());
          break;
      }
      return status;
    },
  },
};
</script>
