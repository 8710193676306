<template>
  <CustomBottomSheet
    :refName="'UserInfo'"
    size="xl"
    :headerText="$t('Users.data')"
    :headerIcon="user.icon"
    @opened="getUserDetails()"
  >
    <PreLoader v-if="isLoading" />

    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />
    <div v-else class="row">
      <DataLabelGroup
        :className="'col-md-6'"
        :value="user.fullCode"
        :title="$t('code')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="user.userTypeNameCurrent"
        :title="$t('Users.type')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-md-6'"
        :value="user.userNameAr"
        :title="$t('Users.nameAr')"
        :imgName="'users.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="user.userNameEn"
        :title="$t('Users.nameEn')"
        :imgName="'users.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="user.userNameUnd"
        :title="$t('Users.nameUnd')"
        :imgName="'users.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6 phone-number'"
        :value="user.userPhoneWithCC"
        :title="$t('Users.phone')"
        :imgName="'phone.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="user.userEmail"
        :title="$t('Users.email')"
        :imgName="'email.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="user.userName"
        :title="$t('Users.userName')"
        :imgName="'user.svg'"
      />

      <DataLabelGroup
        :className="'col-md-6'"
        :value="
          user.establishmentRoleData
            ? user.establishmentRoleData.establishmentRoleNameCurrent
            : ''
        "
        :title="$t('EstablishmentRoles.name')"
        :imgName="'type.svg'"
      />
      <!-- userProfileData -->
      <template v-if="user.userProfileData">
        <DataLabelGroup
          :className="'col-md-6'"
          :value="user.userProfileData.userGenderNameCurrent"
          :title="$t('Users.gender')"
          :imgName="'gender.svg'"
        />
        <DataLabelGroup
          v-if="user.userTypeToken == USER_TYPE.Employee"
          :className="'col-md-6'"
          :value="user.userProfileData.userLocalJobNameCurrent"
          :title="$t('Jobs.name')"
          :imgName="'jobs.svg'"
        />
        <DataLabelGroup
          :className="
            user.userTypeToken != USER_TYPE.Employee ? 'col-md-12' : 'col-md-6'
          "
          :value="user.userProfileData.religionNameCurrent"
          :title="$t('Religions.name')"
          :imgName="'religions.svg'"
        />
      </template>
    </div>
  </CustomBottomSheet>
</template>

<script>
import PreLoader from "./../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../components/general/ExceptionWithImg.vue";
import CustomBottomSheet from "./../../components/general/CustomBottomSheet.vue";
import DataLabelGroup from "./../../components/general/DataLabelGroup.vue";
import { formateDateTimeLang } from "./../../utils/functions";
import userDetailsMixin from "./userDetailsMixin";
import { USER_TYPE } from "./../../utils/constantLists";

export default {
  mixins: [userDetailsMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomBottomSheet,
    DataLabelGroup,
  },
  // props: ["user"],
  // props: ["userToken"],
  methods: {
    formateDateTimeLang,
  },
  data() {
    return {
      USER_TYPE,
    };
  },
};
</script>
